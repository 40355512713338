import { getPreferredColourScheme } from "./ColourMode";
import iconDark from "../../img/icon-dark.png";
import iconLight from "../../img/icon.png";

function Logo({ height }: { height?: string }) {
  const colourScheme = getPreferredColourScheme();
  return (
    <img
      src={colourScheme === "light" ? iconLight : iconDark}
      style={{ height: height || "75px", minHeight: "unset" }}
    />
  );
}

export default Logo;
