export type ColourScheme = "dark" | "light";

function setColourScheme(scheme: ColourScheme) {
  console.log(`Setting colour scheme to: ${scheme}`);
  document.documentElement.setAttribute("data-bs-theme", scheme);
}

export function getPreferredColourScheme(): ColourScheme {
  if (window.matchMedia) {
    if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
      return "dark";
    } else {
      return "light";
    }
  }
  return "light";
}

function updateColourScheme() {
  setColourScheme(getPreferredColourScheme());
}

export function setupColourScheme() {
  if (window.matchMedia) {
    const colorSchemeQuery = window.matchMedia("(prefers-color-scheme: dark)");
    colorSchemeQuery.addEventListener("change", updateColourScheme);
  }

  updateColourScheme();
}
