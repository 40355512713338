import { getPreferredColourScheme } from "../components/ColourMode";
import { AccountEntry, Entry, MoreLikeThis } from "../components/Data";
import { errorCode, errorParser, fetchJSON } from "../components/Loaders";
import { IUIStore, MAX_SCORED_ENTRIES, useUIStore } from "../components/Store";
import { QueryClient, useQueryClient } from "@tanstack/react-query";
import { produce } from "immer";
import React from "react";
import { Link } from "react-router-dom";

export type RescoreType = (
  queryClient: QueryClient,
  entryId: string,
) => Promise<void>;

function TagList({ accountEntry }: { accountEntry: AccountEntry }) {
  const tags = Object.keys(accountEntry.tags);
  tags.sort();
  return (
    <>
      {tags.length > 0 && (
        <>
          {tags
            .filter(
              (tag_name) =>
                !tag_name.startsWith("feed:") && !tag_name.startsWith("site:"),
            )
            .map((tag_name) => {
              const tag = accountEntry.tags[tag_name];
              const urlName = tag_name.replaceAll(
                new RegExp("[^A-Za-z]", "g"),
                "",
              );
              return (
                <React.Fragment key={tag_name}>
                  <span key={tag_name} data-testid={`tag-${tag_name}`}>
                    <Link to={`/Tag/${tag.id}-${urlName}?name=${tag.display}`}>
                      {tag.display}
                    </Link>
                  </span>{" "}
                </React.Fragment>
              );
            })}
          <br />
        </>
      )}
    </>
  );
}

const scoreItem = async ({
  queryClient,
  entryId,
  kind,
  onSuccessfulRescore,
}: {
  queryClient: QueryClient;
  entryId: string;
  kind: string;
  onSuccessfulRescore: RescoreType;
}) => {
  useUIStore.setState(
    produce((s: IUIStore) => {
      s.scoredEntries.push(entryId);
      if (s.scoredEntries.length > MAX_SCORED_ENTRIES) {
        s.scoredEntries.shift();
      }
    }),
  );

  await fetchJSON({
    url: `/api/entry/${entryId}/${kind}`,
    method: "PUT",
  })
    .then((result) =>
      Promise.all([
        onSuccessfulRescore(queryClient, entryId),
        Promise.resolve(result.data),
      ]),
    )
    .catch((error) => {
      const code = errorCode(error);
      if (code === 404) {
        // 404's aren't _ok_ as such, but from a frontend perspective we do the same actions
        // The backend hasn't recorded them though
        return null;
      }
      return Promise.reject(errorParser("Couldn't score item", error));
    });
};

function Buttons({
  queryClient,
  entry,
  onSuccessfulRescore,
  choice,
}: {
  queryClient: QueryClient;
  entry: Entry;
  onSuccessfulRescore: RescoreType;
  choice: MoreLikeThis | null;
}) {
  return (
    <>
      <button
        id={"more-" + entry.id}
        className={
          "btn more-btn " +
          (choice === "MORE" || choice === null
            ? "btn-success"
            : "btn-outline-success")
        }
        data-testid={"more-" + entry.id}
        onClick={(e) => {
          void scoreItem({
            queryClient,
            entryId: entry.id,
            kind: "more",
            onSuccessfulRescore,
          });
          e.preventDefault();
        }}
      >
        More
      </button>
      <span>&nbsp;</span>
      <button
        id={"meh-" + entry.id}
        data-testid={"meh-" + entry.id}
        className={
          "btn meh-btn " +
          (choice === "MEH" || choice === null
            ? "btn-secondary"
            : "btn-outline-secondary")
        }
        onClick={(e) => {
          void scoreItem({
            queryClient,
            entryId: entry.id,
            kind: "meh",
            onSuccessfulRescore,
          });
          e.preventDefault();
        }}
      >
        Meh
      </button>
      <span>&nbsp;</span>
      <button
        id={"less-" + entry.id}
        data-testid={"less-" + entry.id}
        className={
          "btn less-btn " +
          (choice === "LESS" || choice === null
            ? "btn-danger"
            : "btn-outline-danger")
        }
        onClick={(e) => {
          void scoreItem({
            queryClient,
            entryId: entry.id,
            kind: "less",
            onSuccessfulRescore,
          });
          e.preventDefault();
        }}
      >
        Less
      </button>
      <span> </span>
    </>
  );
}

export function DisplayEntry({
  accountEntry,
  onSuccessfulRescore,
  hasClicked,
  setHasClicked,
}: {
  accountEntry: AccountEntry;
  hasClicked: boolean;
  setHasClicked: () => void;
  onSuccessfulRescore: RescoreType;
}) {
  const entry = accountEntry.entry;
  const queryClient = useQueryClient();
  const colourScheme = getPreferredColourScheme();

  return (
    <div
      id={`entry-${entry.id}`}
      key={entry.id}
      className={`entry-line ${
        hasClicked
          ? colourScheme === "light"
            ? "bg-dark-subtle"
            : "bg-light-subtle"
          : colourScheme === "light"
            ? "text-bg-light"
            : "text-bg-dark"
      }`}
      data-testid={`entry-${entry.id}`}
    >
      <a
        className="fs-5 entryLink"
        data-testid={"link-" + entry.id}
        href={entry.url}
        target="_blank"
        // Because if we disable the event, the link stops working!
        // eslint-disable-next-line require-prevent-default/require-prevent-default
        onClick={setHasClicked}
      >
        {entry.title}
      </a>{" "}
      - {entry.publish_date.toRelative()}
      <br />
      <Buttons
        queryClient={queryClient}
        entry={entry}
        onSuccessfulRescore={onSuccessfulRescore}
        choice={accountEntry.more_like_this}
      />
      <br style={{ paddingTop: "5px", paddingBottom: "5px" }} />
      <TagList accountEntry={accountEntry} />
      <br className="d-xs-none d-md-inline" />
    </div>
  );
}
