import { AccountState, Result } from "../components/Data";
import Footer from "../components/Footer";
import {
  errorData,
  errorParser,
  fetchJSON,
  hasWorkingFeedsTest,
  LOAD_ACCOUNT,
  LOAD_VERSION_KEY,
  loadVersion,
  useAccount,
  useFeeds,
} from "../components/Loaders";
import Logo from "../components/Logo";
import { defaultData, useUIStore } from "../components/Store";
import { AddToast, Toasts } from "../components/Toasts";
import { TopEntries } from "../tabs/Entries";
import Feeds from "../tabs/Feeds";
import { useIsFetching, useQuery, useQueryClient } from "@tanstack/react-query";
import { isUndefined } from "lodash";
import { JSX, useEffect, useState } from "react";
import {
  Link,
  Outlet,
  useHref,
  useLocation,
  useOutlet,
  useSearchParams,
} from "react-router-dom";
import { useShallow } from "zustand/shallow";

enum Tabs {
  Best = "Best",
  Recent = "Recent",
  Tags = "Tags",
  Feeds = "Feeds",
  Users = "Users",
  History = "History",
  Search = "Search",
}

function Home() {
  const sessionId = useUIStore((s) => s.sessionId);
  const backendVersion = useQuery({
    queryKey: LOAD_VERSION_KEY,
    queryFn: loadVersion(sessionId),
  });
  const versionDiff =
    backendVersion.isSuccess &&
    backendVersion.data.version !== __FRONTEND_VERSION__;
  const location = useLocation();
  const queryClient = useQueryClient();
  const logoutAction = () =>
    fetchJSON<Result>({ url: "/api/logout", method: "POST" })
      .then((result) => {
        useUIStore.setState(defaultData());
        void queryClient.cancelQueries().then(() => {
          queryClient.clear();
        });
        return result.data;
      })
      .catch((error) => Promise.reject(errorParser("logout", error)));
  let tab: Tabs;
  const feeds = useFeeds(sessionId);
  const hasWorkingFeeds = hasWorkingFeedsTest(feeds);
  const hasAnyFeeds = feeds.isSuccess ? feeds.data.length > 0 : true; // default to true so we don't do anything until feeds are actually loaded
  useEffect(() => {
    if (!hasAnyFeeds && location.pathname === "/") {
      window.location.replace("/#/Feeds");
    }
  }, [hasAnyFeeds, location.pathname]);
  const email = useUIStore(
    useShallow((s) => (s.loginData === null ? "" : s.loginData.email)),
  );
  const account = useAccount(sessionId);
  const outlet = useOutlet();
  const [searchParams, _] = useSearchParams();
  const rootURL = useHref("/");
  const [triedRegistration, setTriedRegistration] = useState(false);
  const isFetching = useIsFetching();

  if (!account.isSuccess) {
    return <span>Loading account...</span>;
  }

  if (location.pathname === "/Best") {
    tab = Tabs.Best;
  } else if (location.pathname === "/Recent") {
    tab = Tabs.Recent;
  } else if (
    location.pathname === "/Feeds" ||
    location.pathname.startsWith("/Feed")
  ) {
    tab = Tabs.Feeds;
  } else if (location.pathname === "/Users") {
    tab = Tabs.Users;
  } else if (
    location.pathname === "/Tags" ||
    location.pathname.startsWith("/Tag")
  ) {
    tab = Tabs.Tags;
  } else if (location.pathname === "/History") {
    tab = Tabs.History;
  } else if (location.pathname.startsWith("/Search")) {
    tab = Tabs.Search;
  } else {
    tab = hasWorkingFeeds ? Tabs.Best : Tabs.Feeds;
  }

  const isAdmin = (account.data?.groups ?? []).includes("admin");
  let validTabs = hasWorkingFeeds ? Object.keys(Tabs) : ["Feeds", "Users"];
  if (!isAdmin) {
    validTabs = validTabs.filter((t) => t !== "Users");
  }
  const outletElement =
    outlet === null ? (
      hasWorkingFeeds ? (
        <TopEntries sessionId={sessionId} />
      ) : (
        <Feeds />
      )
    ) : (
      <Outlet />
    );

  function notYetDisplay(message_id: string, message: string): JSX.Element {
    return (
      <div className="px-4 py-5 my-5 text-center">
        <h1 className="display-5 fw-bold text-body-emphasis">Freshet</h1>
        <div className="col-lg-6 mx-auto">
          <p className="lead mb-4" data-testid={message_id} id={message_id}>
            {message}
          </p>
          <div className="d-grid gap-2 d-sm-flex justify-content-sm-center">
            <button
              type="button"
              className="btn btn-primary btn-lg px-4 gap-3"
              data-testid="refetch-button"
              onClick={(e) => {
                AddToast("Refreshing account status");
                void account.refetch();
                e.preventDefault();
              }}
            >
              Refresh status
            </button>
            <button
              type="button"
              data-testid="logout"
              className="btn btn-secondary btn-lg"
              onClick={(e) => {
                void logoutAction();
                e.preventDefault();
              }}
            >
              Log out
            </button>
          </div>
        </div>
      </div>
    );
  }

  let content: JSX.Element;
  if (account.data.state === AccountState.REGISTERED) {
    if (location.pathname.startsWith("/Registration")) {
      const token = searchParams.get("token");
      if (token !== null && !triedRegistration) {
        fetchJSON<Result>({
          url: "/api/verify_token",
          method: "POST",
          content: {
            email: account.data.email,
            token,
          },
        })
          .then((result) => {
            setTriedRegistration(true);
            AddToast(result.data["message"]);
            window.location.replace(rootURL);
            return queryClient.invalidateQueries({ queryKey: LOAD_ACCOUNT });
          })
          .catch((error) => {
            const r = errorData(error) as Result;
            AddToast(r["message"]);
            console.warn(errorParser("Bad registration", error));
          });
      }
    }

    content = notYetDisplay(
      "registration-message",
      "Thanks for registering. Please click the link in your email to verify your email, or click the button below to refresh the page if you've already done that.",
    );
  } else if (account.data.state === AccountState.VERIFIED) {
    content = notYetDisplay(
      "verification-message",
      "Thanks for verifying your email. We have currently reached our maximum amount of users that we can support at the moment, but should be expanding that soon. You are in a queue for new accounts, and we will email you once we have more capacity.",
    );
  } else {
    const minMobileSize = "md";
    const logout = (className: string) => (
      <p className={className}>
        {email}
        <br />
        {/* eslint-disable-next-line idiomatic-jsx-u/require-attributes */}
        <a
          className="btn btn-primary"
          role="button"
          href="#"
          id="logout"
          data-testid="logout"
          onClick={(e) => {
            void logoutAction();
            e.preventDefault();
          }}
        >
          Log out
        </a>
      </p>
    );
    const updateLink = (className: string) =>
      versionDiff ? (
        /* eslint-disable-next-line idiomatic-jsx-u/require-attributes */
        <a
          data-testid="update-to-latest"
          className={className}
          href=""
          onClick={(e) => {
            e.preventDefault();
            window.location.reload();
          }}
        >
          Click to update to latest version
        </a>
      ) : (
        <></>
      );
    const spinner = (size?: string) =>
      isFetching ? (
        <div
          data-testid="loading-spinner"
          className="spinner-border text-info"
          style={isUndefined(size) ? undefined : { width: size, height: size }}
          role="status"
        ></div>
      ) : (
        <></>
      );
    content = (
      <>
        <div className={`row d-none d-${minMobileSize}-flex`}>
          <h3 className={`col-none col-${minMobileSize}-auto`}>
            <Logo />
            Freshet&nbsp;
            {spinner()}
          </h3>
          {updateLink(`col-none col-${minMobileSize}-auto`)}
          {logout(
            `col-none col-${minMobileSize}-auto ms-${minMobileSize}-auto`,
          )}
        </div>
        <div className="row">
          <div
            className={`col-${minMobileSize}-2 order-1 order-${minMobileSize}-2`}
          >
            <h3 className={`d-block d-${minMobileSize}-none`}>
              <Logo height="40px" />
              Freshet&nbsp;
              {spinner("30px")}
            </h3>
            {updateLink(`d-block d-${minMobileSize}-none`)}
            {logout(`d-block d-${minMobileSize}-none`)}
            <p />
          </div>
          <div
            className={`col-${minMobileSize} order-2 order-${minMobileSize}-1`}
          >
            <ul className="nav nav-tabs">
              {validTabs.map((key) => {
                const lowerKey = key.toLowerCase();
                const enumKey = Tabs[key as keyof typeof Tabs];
                return (
                  <li
                    key={key}
                    data-testid={`nav-item-${lowerKey}`}
                    className={`nav-tab nav-item-${lowerKey}`}
                  >
                    <Link
                      className={
                        "nav-link" + (tab === enumKey ? " active" : "")
                      }
                      data-testid={`nav-${lowerKey}${
                        tab === enumKey ? "-active" : ""
                      }`}
                      to={`/${key}`}
                    >
                      {key}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        <div className="row-fluid">{outletElement}</div>{" "}
      </>
    );
  }

  return (
    <main
      role="main"
      className="container-fluid"
      data-testid="home-panel"
      style={{ paddingTop: ".5rem" }}
    >
      <Toasts />
      {content}
      <hr />
      <Footer />
    </main>
  );
}

export default Home;
